import { TeamType, ToolsSubscriptionFragment } from "../Common/Fragments/ToolsSubscriptionFragment"

export enum BlockedBetsDurationType {
  DAYS = 'DAYS',
  WEEKS = 'WEEKS'
}

export const BlockedBetsDurationTypeMssgMap = {
  [BlockedBetsDurationType.DAYS]: 'days',
  [BlockedBetsDurationType.WEEKS]: 'weeks'
}

export type BlockedBetDateType = {
  // durationValue: number,
  // durationType: BlockedBetsDurationType,
  expirationDate: number,
}

export type BlockedBetRowType = {
  teams: (TeamType | null)[],
  gameStartTime: number | undefined,
  displayName: string | undefined,
} & Pick<ToolsSubscriptionFragment, 'betMarketSiteIds' | 'id' | 'betValues' | 'overOrUnder' | 'betMarketId'>;

export type BlockedBetsBetType = BlockedBetDateType & BlockedBetRowType;