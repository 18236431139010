import React, { useRef, useState, useEffect } from "react";
import { ApplyButtonLong } from "@/components/SelectMenu/SelectMenuApply";
import { useMutation } from "@apollo/client";
import { TurnstileInstance } from "@marsidev/react-turnstile";
import { Box, TextField, Typography, useTheme } from "@mui/material";
import separateErrorCodeAndMssgFromError from "@/utis/getErrorCodeFromMessage";
import { useTranslations } from "next-intl";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LogoProgressSpinner from "@/components/LogoProgressSpinner";
import {
  UserSignup,
  UserSignupVariables,
  USER_SIGNUP,
  generateHashKey,
  LoginType,
  LoginAuthenticationMethodEnum,
} from "./common";
import LoginVericationCode, { StyledTurnstile } from "./LoginVericationCode";

export type LoginPopupEmailSectionProps = {
  loginType?: LoginType;
  onClose: () => void;
  setSucess: (val: boolean) => void;
  email?: string;
  setShowPlansModal: (val: boolean) => void;
};

function LoginPopupEmailSection({
  loginType,
  onClose: onCloseProp,
  setSucess,
  email: emailProps,
  setShowPlansModal,
}: LoginPopupEmailSectionProps) {
  const [emailAddress, setEmailAddress] = useState<string>(emailProps || "");
  const [emailError, setEmailError] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string>();
  const [hashCode, setHashCode] = useState<string>("");
  const emailInputRef = useRef<HTMLInputElement>(null);
  const turnStileRef = useRef<TurnstileInstance>(null);
  const theme = useTheme();
  const [verificationType, setverificationType] = useState<LoginAuthenticationMethodEnum>();
  const [handleUserSignup, { loading, error, data }] = useMutation<UserSignup, UserSignupVariables>(USER_SIGNUP, {
    onCompleted: async (resData) => {
      setverificationType(resData.user.signup);
      setSucess(true);
    },
    onError: () => turnStileRef.current?.reset(),
  });

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
      emailInputRef.current.blur();
    }
  }, []);

  const handleVerify = (captchaTokenApi: string) => {
    setCaptchaToken(captchaTokenApi);
  };

  const signInClicked = () => {
    const emailAddressError = !/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/.test(emailAddress);
    if (emailAddressError) {
      setEmailError(true);
      return;
    }
    const newHashCode = generateHashKey();
    setHashCode(newHashCode);
    handleUserSignup({
      variables: {
        email: emailAddress,
        cfTurnstileToken: captchaToken!,
        hashCode: newHashCode,
      },
    });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value.trim();
    setEmailAddress(email);
    if (!emailError) return;
    if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$/.test(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const disableSinupBtn = emailAddress.trim().length === 0 || emailError || !captchaToken || loading;
  const t = useTranslations("Login.LoginPopup");

  function getLoginBtnContent() {
    if (loading) {
      return <LogoProgressSpinner />;
    }
    if (data) {
      return <CheckCircleIcon />;
    }
    if (loginType === LoginType.LOG_IN) {
      return t("login");
    }
    return t("sign_up");
  }

  return (
    <>
      {!data && (
        <>
          {error ? (
            <Typography variant="body2" color="error" mb={2} mt={2}>
              {separateErrorCodeAndMssgFromError(error.message)?.[1]}
            </Typography>
          ) : (
            <Typography variant="body2" mb={2} mt={2} color="text.secondary">
              {loginType === LoginType.SIGN_UP ? t("sign_up_message") : t("login_message")}
            </Typography>
          )}
          <TextField
            inputRef={emailInputRef}
            fullWidth
            error={emailError}
            helperText={emailError ? "Invalid email address" : undefined}
            placeholder="Email Address"
            onChange={handleEmailChange}
            disabled={loading}
            size="small"
            sx={{
              input: {
                pt: 1.5,
                pb: 1.5,
                fontSize: theme.typography.body3.fontSize,
              },
            }}
            InputProps={{
              inputProps: { maxLength: 200 },
              startAdornment: <MailOutlineIcon sx={{ fontSize: 20, color: "text.secondary", mr: 1 }} />,
            }}
            value={emailAddress}
            variant="filled"
          />
          <Box height={65} my={2}>
            <StyledTurnstile
              ref={turnStileRef}
              siteKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
              options={{ theme: theme.palette.mode, retry: "never" }}
              style={{ width: "100%" }}
              onSuccess={handleVerify}
            />
          </Box>
          <ApplyButtonLong
            color="secondary"
            variant="contained"
            disabled={disableSinupBtn}
            onClick={signInClicked}
            size="large"
            sx={{ height: 56, mt: 3 }}
          >
            {getLoginBtnContent()}
          </ApplyButtonLong>
        </>
      )}
      {data && (
        <LoginVericationCode
          hashCode={hashCode}
          emailAddress={emailAddress}
          onClose={onCloseProp}
          setShowPlansModal={setShowPlansModal}
          verificationType={verificationType!}
        />
      )}
    </>
  );
}

export default LoginPopupEmailSection;
