import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

function BookInfoIcon(props: SvgIconProps) {
  return (
    <SvgIcon width={21} height={29} viewBox="0 0 21 29" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M0 4V25C0 25.9283 0.368749 26.8185 1.02513 27.4749C1.6815 28.1313 2.57174 28.5 3.5 28.5H20.125C20.3571 28.5 20.5796 28.4078 20.7437 28.2437C20.9078 28.0796 21 27.8571 21 27.625C21 27.3929 20.9078 27.1704 20.7437 27.0063C20.5796 26.8422 20.3571 26.75 20.125 26.75H3.5C3.03587 26.75 2.59075 26.5656 2.26256 26.2374C1.93437 25.9093 1.75 25.4641 1.75 25H19.25C19.7141 25 20.1592 24.8156 20.4874 24.4874C20.8156 24.1592 21 23.7141 21 23.25V4C21 3.07174 20.6313 2.1815 19.9749 1.52513C19.3185 0.868749 18.4283 0.5 17.5 0.5H3.5C2.57174 0.5 1.6815 0.868749 1.02513 1.52513C0.368749 2.1815 0 3.07174 0 4ZM17.5 2.25C17.9641 2.25 18.4092 2.43437 18.7374 2.76256C19.0656 3.09075 19.25 3.53587 19.25 4V23.25H1.75V4C1.75 3.53587 1.93437 3.09075 2.26256 2.76256C2.59075 2.43437 3.03587 2.25 3.5 2.25H17.5ZM11.8125 7.0625C11.8125 6.7144 11.6742 6.38056 11.4281 6.13442C11.1819 5.88828 10.8481 5.75 10.5 5.75C10.1519 5.75 9.81806 5.88828 9.57192 6.13442C9.32578 6.38056 9.1875 6.7144 9.1875 7.0625C9.1875 7.4106 9.32578 7.74444 9.57192 7.99058C9.81806 8.23672 10.1519 8.375 10.5 8.375C10.8481 8.375 11.1819 8.23672 11.4281 7.99058C11.6742 7.74444 11.8125 7.4106 11.8125 7.0625ZM11.375 18.875C11.375 19.1071 11.2828 19.3296 11.1187 19.4937C10.9546 19.6578 10.7321 19.75 10.5 19.75C10.2679 19.75 10.0454 19.6578 9.88128 19.4937C9.71719 19.3296 9.625 19.1071 9.625 18.875V11.875C9.625 11.6429 9.71719 11.4204 9.88128 11.2563C10.0454 11.0922 10.2679 11 10.5 11C10.7321 11 10.9546 11.0922 11.1187 11.2563C11.2828 11.4204 11.375 11.6429 11.375 11.875V18.875Z" />
    </SvgIcon>
  );
}

export default BookInfoIcon;
