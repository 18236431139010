import { Box, Modal, Stack, Typography, styled, Icon, useTheme, Tabs, Tab } from "@mui/material";
import { HomeOutlined } from "@mui/icons-material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import React, { ComponentProps, useEffect, useState } from "react";
import { useTranslations } from "next-intl";
import AvailablePlansModal from "@/components/AvailablePlansModal/AvailablePlansModal";
import { LoginType } from "./common";
import { StyledBox } from "./LoginVericationCode";
import LoginPopupEmailSection from "./LoginPopupEmailSection";

const StyledModal = styled(Modal)`
  display: flex;
`;

const StyledTab = styled(Tab)<{ isSelected: boolean }>(({ isSelected, theme }) => ({
  background: isSelected ? theme.palette.customBackground.cards : theme.palette.primary.main,
  borderTopLeftRadius: theme.spacing(1),
  borderTopRightRadius: theme.spacing(1),
}));

const StyledTabPanel = styled("div")(({ theme }) => ({
  background: theme.palette.customBackground.cards,
  padding: theme.breakpoints.down("md") ? theme.spacing(1) : theme.spacing(4),
}));

const StyledStack = styled(Stack)(
  ({ theme }) => `
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(2)};

    ${theme.breakpoints.down("md")} {
    padding-top: ${theme.spacing(1)};
    }
  `,
);


type CustomTabPanelLabelProps = {
  children?: React.ReactNode;
  isSelected: boolean;
};

function CustomTabPanelLabel(props: CustomTabPanelLabelProps) {
  const { children, isSelected } = props;
  const theme = useTheme();
  return (
    <Typography variant="header" color={isSelected ? theme.palette.basic : undefined}>
      {children}
    </Typography>
  );
}
type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <StyledTabPanel role="tabpanel" hidden={value !== index} id={`user-control-panel-tabpanel-${index}`} {...other}>
      {value === index && children}
    </StyledTabPanel>
  );
}

type LoginPopupProps = {
  loginType?: LoginType;
  onClose: () => void;
  email?: string;
} & Omit<ComponentProps<typeof Modal>, "children" | "onClose">;

function LoginPopup({ onClose: onCloseProp, loginType = LoginType.LOG_IN, email, ...restProps }: LoginPopupProps) {
  const [tabValue, setTabValue] = useState(Object.values(LoginType).findIndex((val) => val === loginType) || 0);
  useEffect(() => {
    setTabValue(Object.values(LoginType).findIndex((val) => val === loginType));
  }, [loginType]);
  const [success, setSucess] = useState(false);
  const [showPlansModal, setShowPlansModal] = useState(false);

  const onModalClose = () => {
    onCloseProp?.();
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const t = useTranslations("Login.LoginPopup");

  return (
    <>
      <StyledModal onClose={onModalClose} {...restProps}>
        <StyledBox alignSelf="center" pb={2} px={2}>
          <StyledStack direction="row" alignItems="center" justifyContent="center">
            <Icon fontSize="large">{success ? <MailOutlineIcon fontSize="large" /> : <HomeOutlined fontSize="large" />}</Icon>
          </StyledStack>
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              TabIndicatorProps={{
                style: { display: "none" },
              }}
            >
              <StyledTab
                isSelected={tabValue === 0}
                label={<CustomTabPanelLabel isSelected={tabValue === 0}>{t("sign_up")}</CustomTabPanelLabel>}
              />
              <StyledTab
                isSelected={tabValue === 1}
                label={<CustomTabPanelLabel isSelected={tabValue === 1}>{t("login")}</CustomTabPanelLabel>}
              />
            </Tabs>
            <CustomTabPanel value={tabValue} index={0}>
              <LoginPopupEmailSection
                loginType={LoginType.SIGN_UP}
                onClose={onCloseProp}
                setSucess={setSucess}
                email={email}
                setShowPlansModal={setShowPlansModal}
              />
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
              <LoginPopupEmailSection
                loginType={LoginType.LOG_IN}
                onClose={onCloseProp}
                setSucess={setSucess}
                email={email}
                setShowPlansModal={setShowPlansModal}
              />
            </CustomTabPanel>
          </Box>
        </StyledBox>
      </StyledModal>
      {showPlansModal && (
        <StyledModal open={showPlansModal} onClose={() => {
          setShowPlansModal(false);
          window.location.reload();
        }}>
          <AvailablePlansModal />
        </StyledModal>
      )}
    </>
  );
}

export default LoginPopup;
