import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum ServerStatusEnum {
  ONLINE = "ONLINE",
  MAINTENANCE = "MAINTENANCE",
  DEGRADED = "DEGRADED",
  OFFLINE = "OFFLINE",
}

export type ServerStatusType = {
  serverStatusId: ServerStatusEnum | undefined;
  arbitrageCountByBetSite?: number[];
  middleCountByBetSite?: number[];
  freeBetCountByBetSite?: number[];
  lowHoldCountByBetSite?: number[];
  expectedValueCountByBetSite?: number[];
  arbitrageCountByLeague?: number[];
  middleCountByLeague?: number[];
  freeBetCountByLeague?: number[];
  lowHoldCountByLeague?: number[];
  expectedValueCountByLeague?: number[];
  gameCountByLeagueEnumIdx?: number[];
};

export const initialState: ServerStatusType = {
  serverStatusId: undefined,
  arbitrageCountByBetSite: [],
  middleCountByBetSite: [],
  freeBetCountByBetSite: [],
  lowHoldCountByBetSite: [],
  expectedValueCountByBetSite: [],
  arbitrageCountByLeague: [],
  middleCountByLeague: [],
  freeBetCountByLeague: [],
  lowHoldCountByLeague: [],
  expectedValueCountByLeague: [],
  gameCountByLeagueEnumIdx: [],
};

export const toolsSportCounts = createSlice({
  name: "toolsSportCounts",
  initialState,
  reducers: {
    setToolsSportCounts: (state, action: PayloadAction<ServerStatusType>) => {
      const newState = action.payload;
      return newState;
    },
  },
});

export const toolsSportCountsAction = toolsSportCounts.actions;

export default toolsSportCounts.reducer;
