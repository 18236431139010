import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadState, saveState } from "@/utis/localStorage";
import { BlockedBetsDurationType } from "@/components/BlockedBets/common";

const KEY = "LOCAL_DATA";

export type LocalStoreType = {
  alertConfirmed?: boolean;
  sportsBookFilterAlertClosed?: boolean;
  durationValue: number;
  durationType: BlockedBetsDurationType;
};

const initialState: LocalStoreType = loadState(KEY) || {
  durationValue: 1,
  durationType: BlockedBetsDurationType.DAYS,
};

export const localStore = createSlice({
  name: "localStore",
  initialState,
  reducers: {
    setAlertConfirmed: (state) => {
      const newState = state;
      newState.alertConfirmed = true;
      saveState(KEY, newState);
      return newState;
    },
    closeSportsBookFilterAlert: (state) => {
      const newState = state;
      newState.sportsBookFilterAlertClosed = true;
      saveState(KEY, newState);
      return newState;
    },
    setDurationValue(state, action: PayloadAction<number>) {
      const newState = { ...state, durationValue: action.payload };
      saveState("durationValue", action.payload);
      return newState;
    },
    setDurationType(state, action: PayloadAction<BlockedBetsDurationType>) {
      const newState = { ...state, durationType: action.payload };
      saveState("durationType", action.payload);
      return newState;
    },
  },
});

export const localStoreAction = localStore.actions;

export default localStore.reducer;
