import { Box, Stack, Typography, filledInputClasses, styled } from "@mui/material";
import React, { useRef, useState } from "react";
import VerificationInput from "react-verification-input";
import { useMutation } from "@apollo/client";
import PageSpinner from "@/components/PageSpinner";
import { updateUserRefreshTokens } from "@/user/common";
import ErrorBoundaryPopup from "@/components/ErrorBoundary/ErrorBoundaryPopup";
import { useTranslations } from "next-intl";
import {
  VERIFY_AUTHENTICATOR,
  VerifyAuthenticator,
  VerifyAuthenticatorVariables,
} from "@/components/AuthenticatorSetup/authenticatorVerification.graphql";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import { useTheme } from "@/theme/ThemeContext";
import { LoginAuthenticationMethodEnum, USER_SIGNUP, UserSignup, UserSignupVariables } from "./common";
import { Verify, VerifyEmail, VerifyEmailVariables, VERIFY_EMAIL } from "./verifyEmail.graphql";

export const StyledTurnstile = styled(Turnstile)`
  iframe {
    width: 100% !important;
    display: hidden;
  }
`;

export const StyledBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: ${theme.palette.background.default};
    border: 1px solid ${theme.palette.text.primary};
    border-radius: ${theme.spacing(1)};
    padding: ${theme.spacing(3)} ${theme.spacing(2)};
    height: max-content;
    margin: auto;
    position: relative;
    width: 425px;

    ${theme.breakpoints.down("md")} {
      max-width: 425px;
      width: calc(100% - ${theme.spacing(3)});
      padding: ${theme.spacing(1.5)} ${theme.spacing(2)};
    }
  `,
);

type LoginVerificationCodeProps = {
  hashCode: string;
  emailAddress: string;
  onClose: () => void;
  setShowPlansModal: (val: boolean) => void;
  verificationType: LoginAuthenticationMethodEnum;
};

function LoginVericationCode(props: LoginVerificationCodeProps) {
  const {
    emailAddress,
    hashCode,
    onClose: onParentModalClose,
    setShowPlansModal,
    verificationType: verificationTypeProps,
  } = props;
  const theme = useTheme();
  const [error, setError] = useState<Error | null>(null);
  const [verificationCode, setVerificationCode] = useState<string>();
  const [verificationType, setVerificationType] = useState(verificationTypeProps);
  const [forcedVerification, setForcedVerification] = useState(false);
  const turnStileRef = useRef<TurnstileInstance>(null);

  const onError = (err: Error, reset: () => void) => {
    reset();
    setVerificationCode(undefined);
    setError(err);
  };
  const onCompleted = (resData: Verify) => {
    const { refreshToken, userId, isNewUser: newUserFlag } = resData;
    if (refreshToken && userId) {
      updateUserRefreshTokens({
        refreshToken: { token: refreshToken },
        userId,
      });
      if (newUserFlag) {
        onParentModalClose?.();
        setShowPlansModal(true);
      } else {
        onParentModalClose?.();
        setTimeout(() => window.location.reload(), 2000);
      }
    }
  };

  const [handleEmailVerification, { loading: verifyEmailLoading, reset: verifyEmailReset }] = useMutation<
    VerifyEmail,
    VerifyEmailVariables
  >(VERIFY_EMAIL, {
    onError: (err) => {
      onError(err, verifyEmailReset);
    },
    onCompleted: async (resData) => {
      onCompleted(resData.user.email.verify);
    },
  });

  const [handleAuthenticatorVerification, { loading: verifyAuthenticatorLoading, reset: verifyAuthenticatorReset }] = useMutation<
    VerifyAuthenticator,
    VerifyAuthenticatorVariables
  >(VERIFY_AUTHENTICATOR, {
    onError: (err) => {
      onError(err, verifyAuthenticatorReset);
    },
    onCompleted: async (resData) => {
      onCompleted(resData.user.authenticator.verify);
    },
  });

  const [handleUserSignup, { loading: userSignUpLoading }] = useMutation<UserSignup, UserSignupVariables>(USER_SIGNUP, {
    onError: (err) => {
      onError(err, verifyEmailReset);
      turnStileRef.current?.reset();
    },
  });

  const onInputComplete = (code: string) => {
    const variables = {
      variables: {
        hashCode,
        code: code.toUpperCase(),
      },
    };
    if (verificationType === LoginAuthenticationMethodEnum.EMAIL) {
      handleEmailVerification(variables);
    } else {
      handleAuthenticatorVerification(variables);
    }
  };

  const onCloseErrorPopup = () => {
    setError(null);
    verifyEmailReset();
    verifyAuthenticatorReset();
    setVerificationCode(undefined);
  };

  const changeVerificationTypeToEmail = (captchaToken: string) => {
    handleUserSignup({
      variables: {
        email: emailAddress,
        cfTurnstileToken: captchaToken!,
        hashCode,
        forcedMethod: LoginAuthenticationMethodEnum.EMAIL,
      },
    });
    setVerificationType(LoginAuthenticationMethodEnum.EMAIL);
  };

  const handleVerify = (captchaTokenApi: string) => {
    changeVerificationTypeToEmail(captchaTokenApi);
  };
  const t = useTranslations("verification");

  return (
    <Stack direction="column" pb={3} mt={2}>
      <Typography variant="header">{t("verification")}</Typography>
      <Typography variant="body2" color="text.secondary" mb={3} mt={2}>
        {verificationType === LoginAuthenticationMethodEnum.EMAIL
          ? t("verification_email_sent", { emailAddress })
          : t("verification_authenticator")}
      </Typography>
      {verifyEmailLoading || verifyAuthenticatorLoading || userSignUpLoading ? (
        <PageSpinner text="" height="auto" />
      ) : (
        <VerificationInput
          value={verificationCode}
          onComplete={onInputComplete}
          length={6}
          autoFocus
          onChange={(ev) => setVerificationCode(ev.toUpperCase())}
          classNames={{
            container: filledInputClasses.input,
          }}
          containerProps={{ style: { alignSelf: "center" } }}
        />
      )}
      {verificationType === LoginAuthenticationMethodEnum.AUTHENTICATOR && (
        <Typography
          onClick={() => setForcedVerification(true)}
          variant="smallText"
          mt={1}
          color="text.secondary"
          sx={{ cursor: "pointer" }}
        >
          {t("send_email_instead")}
        </Typography>
      )}
      {forcedVerification && (
        <Box height={65} my={2}>
          <StyledTurnstile
            ref={turnStileRef}
            siteKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
            options={{ theme: theme.palette.mode, retry: "never" }}
            style={{ width: "100%" }}
            onSuccess={handleVerify}
          />
        </Box>
      )}
      {error && <ErrorBoundaryPopup message={error.message} onClose={onCloseErrorPopup} />}
    </Stack>
  );
}

export default LoginVericationCode;
