import { gql } from "@apollo/client";
import CryptoJS from "crypto-js";

export enum LoginType {
  SIGN_UP = "SIGN_UP",
  LOG_IN = "LOG_IN",
}

export enum LoginAuthenticationMethodEnum {
  EMAIL = "EMAIL",
  AUTHENTICATOR = "AUTHENTICATOR",
}

export const USER_SIGNUP = gql`
  mutation UserSignup(
    $email: String!
    $cfTurnstileToken: String!
    $hashCode: String!
    $forcedMethod: LoginAuthenticationMethodEnum
  ) {
    user {
      signup(email: $email, cfTurnstileToken: $cfTurnstileToken, hashCode: $hashCode, forcedMethod: $forcedMethod)
    }
  }
`;

export type UserSignup = {
  user: {
    signup: LoginAuthenticationMethodEnum;
  };
};

export type UserSignupVariables = {
  email: string;
  cfTurnstileToken: string;
  hashCode: string;
  forcedMethod?: LoginAuthenticationMethodEnum;
};

export function generateHashKey() {
  // Generate a random string to hash
  const randomString = Math.random().toString(36).substring(2); // Using a random string as an example

  // Create a SHA-256 hash of the random string
  const hash = CryptoJS.SHA256(randomString).toString(CryptoJS.enc.Hex);

  // Return the first 20 characters of the hash key
  return hash.substring(0, 20);
}
