import { Typography, styled } from "@mui/material";

export default styled(Typography, {
  shouldForwardProp: (propName) => propName !== "lineClamp",
})<{ lineClamp?: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: ${(props) => props.lineClamp || 1};
`;
