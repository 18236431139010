import { gql } from "@apollo/client";

export const VERIFY_EMAIL = gql`
  mutation VerifyEmail($hashCode: String!, $code: String!) {
    user {
      email {
        verify(hashCode: $hashCode, code: $code) {
          userId
          accessToken
          refreshToken
          isNewUser
        }
      }
    }
  }
`;

export type Verify = {
  userId: string;
  accessToken: string;
  refreshToken: string;
  isNewUser: boolean;
}

export type VerifyEmail = {
  user: {
    email: {
      verify: Verify;
    };
  };
};

export type VerifyEmailVariables = {
  hashCode: string;
  code: string;
};