import { gql } from "@apollo/client";
import { Verify } from "../Login/LoginPopup/verifyEmail.graphql";


export const VERIFY_AUTHENTICATOR = gql`
  mutation VerifyAuthenticator($hashCode: String!, $code: String!) {
    user {
      authenticator {
        verify(hashCode: $hashCode, code: $code) {
          userId
          accessToken
          refreshToken
          isNewUser
        }
      }
    }
  }
`;

export type VerifyAuthenticator = {
  user: {
    authenticator: {
      verify: Verify;
    };
  };
};

export type VerifyAuthenticatorVariables = {
  hashCode: string;
  code: string;
};


