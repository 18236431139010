"use client";

import { KeyOfPages } from "@/components/Common/Filters/commonFilterType";
import { usePathname } from "next/navigation";

export default function useGetPathKey<T = KeyOfPages>() {
  const pathname = usePathname();
  const [, , pathKey] = pathname.split("/") as T[];
  return pathKey;
}
